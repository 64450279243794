import axios from '@/plugins/axios';

class AuthService {
  login(user) {
    this.user = user;

    return axios().post('auth/login', user).then((response) => {
      if (response.data.token) {
        localStorage.setItem('yup_chat_token', JSON.stringify(response.data.token));
      }
      return response.data.token;
    });
    // return Promise.resolve(this.user);
  }

  logout() {
    this.a = 'a';
    localStorage.removeItem('yup_chat_token');
  }

  register(data) {
    this.data = data;
    return axios().post('auth/register', data).then((response) => {
      if (response.data.token) {
        localStorage.setItem('yup_chat_token', JSON.stringify(response.data.token));
      }
      return response.data.token;
    });
  }

  registerTrial(data) {
    this.data = data;
    return axios().post('auth/register/trial', data).then((response) => {
      if (response.data.token) {
        localStorage.setItem('yup_chat_token', JSON.stringify(response.data.token));
      }
      return response.data.token;
    });
  }

  reset(data) {
    this.data = data;
    return axios().post('auth/reset-password', data);
  }

  resetVerification(token) {
    this.token = token;
    return axios().get(`auth/reset/${token}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data.message));
  }

  resetPassword(data) {
    this.data = data;
    return axios().post('auth/reset-verification', data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data.message));
  }

  refresh() {
    this.a = 'a';
    return axios().get('auth/token').then((response) => {
      const token = response?.data?.token;

      if (token) {
        const tfa = localStorage.getItem('yup_chat_tfa');
        const jtoken = JSON.stringify(token);

        localStorage.setItem('yup_chat_token', jtoken);

        if (tfa) localStorage.setItem('yup_chat_tfa', jtoken);
      }

      return token;
    });
  }
}

export default new AuthService();
